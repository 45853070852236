// @ts-ignore

import * as React from 'react';
import styled from '@emotion/styled';
import {
  useTransition,
  animated as a,
  interpolate,
} from 'react-spring';
import {
  vWidth,
  vHeight,
  formations,
  LogoElement,
} from './data';

interface SvgContainerProps {
  children: React.ReactNode;
  width: number;
  height: number;
}

const Container = styled.div`
  width: 100%;
`;

const SvgContainer: React.FC<SvgContainerProps> = ({
  children,
  width,
  height,
}) => (
  <svg
    viewBox={`0 0 ${width} ${height}`}
    style={{
      display: 'block',
      width: '100%',
      backgroundColor: '#171717',
    }}
  >
    <defs>
      <filter id="blur" colorInterpolationFilters="sRGB">
        <feGaussianBlur stdDeviation="2.723" />
      </filter>
    </defs>
    {children}
  </svg>
);

interface OffsetGroupProps {
  children: React.ReactNode;
  width: number;
  height: number;
  offsetX: number;
  offsetY: number;
}

const OffsetGroup: React.FC<OffsetGroupProps> = ({
  children,
  width,
  height,
  offsetX,
  offsetY,
}) => (
  <g
    width={width}
    height={height}
    transform={`translate(${offsetX} ${offsetY})`}
  >
    {children}
  </g>
);

type Coordinates = [number, number];

interface VProps {
  xy: AnimatedValueArray;
  rotation: number;
  rotationOffset?: Coordinates | null | undefined;
  fill: string;
  fillOpacity: number;
  filter?: string | null | undefined;
}

const V: React.FC<VProps> = ({
  xy,
  rotation,
  rotationOffset = [0, 0],
  fill,
  fillOpacity,
  filter,
}) => {
  const trns = interpolate(
    [
      xy.interpolate((x, y) => `translate(${x} ${y})`),
      interpolate(
        [rotation.interpolate((r) => `${r}`), rotationOffset.interpolate((x, y) => `${x} ${y}`)],
        (rotation, offset) => `rotate(${rotation} ${offset})`,
      ),
    ],
    (translate, rotate) => `${translate} ${rotate}`,
  );

  return (
    <a.path
      transform={trns}
      fill={fill}
      fillOpacity={fillOpacity}
      filter={filter && `url(#${filter})`}
      d="M39.68 0L-.004 68.742l17.641-.002 22.045-38.189 22.051 38.188h17.639L39.681.001z"
    />
  );
};

const transformer = (defaults) => ({
  xy,
  rotation,
  rotationOffset,
  fill,
  fillOpacity,
}) => {
  return {
    xy,
    rotation,
    rotationOffset: rotationOffset || [vWidth / 2, vHeight / 2],
    fill,
    fillOpacity,
    ...defaults,
  };
};

interface Props {
  formationIndex?: number;
}

const AnimatedLogo: React.FC<Props> = ({
  formationIndex = 0,
}) => {
  const svgWidth = 640;
  const svgHeight = 480;

  const formation = formations[formationIndex % formations.length];

  const transitions = useTransition<LogoElement>(formation, (el: LogoElement): string => el.id, {
    from: transformer({ opacity: 0 }),
    enter: transformer({ opacity: 1 }),
    update: transformer({}),
    leave: transformer({ opacity: 0 }),
    config: { mass: 6, tension: 500, friction: 100 },
    trail: 25,
  });

  return (
    <Container>
      <SvgContainer
        width={svgWidth}
        height={svgHeight}
      >
        <OffsetGroup
          width={svgWidth}
          height={svgHeight}
          offsetX={(svgWidth - vWidth) / 2}
          offsetY={(svgHeight - vHeight) / 2}
        >
          {transitions.map(({ key, item, props }) => {
            return <V key={key} {...item} {...props} />;
          })}
        </OffsetGroup>
      </SvgContainer>
    </Container>
  );
};

export default AnimatedLogo;
