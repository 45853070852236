export interface LogoElement {
  id: string;
  type: 'v';
  xy: [number, number];
  rotation?: number;
  rotationOffset?: [number, number];
  fill: string;
  fillOpacity?: number;
  filter?: string | null | undefined;
}

export type Formation = LogoElement[];

export const vWidth = 79.375;
export const vHeight = 68.743;

const formation0 = [
  {
    id: 'm0blur',
    type: 'v',
    xy: [0, 0],
    rotation: 0,
    fill: '#7a970a',
    fillOpacity: 0,
    filter: 'blur',
  },
  {
    id: 'm1blur',
    type: 'v',
    xy: [0, 0],
    rotation: 0,
    fill: '#ccff00',
    fillOpacity: 0,
    filter: 'blur',
  },
  {
    id: 'a0blur',
    type: 'v',
    xy: [0, 0],
    rotation: 0,
    fill: '#7a970a',
    fillOpacity: 0,
    filter: 'blur',
  },
  {
    id: 'a1blur',
    type: 'v',
    xy: [0, 0],
    rotation: 60,
    fill: '#ccff00',
    fillOpacity: 0,
    filter: 'blur',
  },
  {
    id: 'x0blur',
    type: 'v',
    xy: [0, 0],
    rotation: 0,
    fill: '#7a970a',
    fillOpacity: 0,
    filter: 'blur',
  },
  {
    id: 'x1blur',
    type: 'v',
    xy: [0, 0],
    rotation: 180,
    fill: '#ccff00',
    fillOpacity: 0,
    filter: 'blur',
  },
  {
    id: 'm0',
    type: 'v',
    xy: [0, vHeight / 2],
    rotation: 180,
    rotationOffset: [vWidth / 2, 0],
    fill: '#ccff00',
    fillOpacity: 1,
  },
  {
    id: 'm1',
    type: 'v',
    xy: [0, vHeight / 2],
    rotation: 120,
    rotationOffset: [vWidth / 2, 0],
    fill: '#ccff00',
    fillOpacity: 1,
  },
  {
    id: 'a0',
    type: 'v',
    xy: [0, vHeight / 2],
    rotation: 60,
    rotationOffset: [vWidth / 2, 0],
    fill: '#ccff00',
    fillOpacity: 1,
  },
  {
    id: 'a1',
    type: 'v',
    xy: [0, vHeight / 2],
    rotation: 240,
    rotationOffset: [vWidth / 2, 0],
    fill: '#ccff00',
    fillOpacity: 1,
  },
  {
    id: 'x0',
    type: 'v',
    xy: [0, vHeight / 2],
    rotation: 300,
    rotationOffset: [vWidth / 2, 0],
    fill: '#ccff00',
    fillOpacity: 1,
  },
  {
    id: 'x1',
    type: 'v',
    xy: [0, vHeight / 2],
    rotation: 360,
    rotationOffset: [vWidth / 2, 0],
    fill: '#ccff00',
    fillOpacity: 1,
  },
];

const formation1 = [
  {
    id: 'm0blur',
    type: 'v',
    xy: [-100.0125, 0],
    rotation: 0,
    fill: '#7a970a',
    fillOpacity: 1,
    filter: 'blur',
  },
  {
    id: 'm1blur',
    type: 'v',
    xy: [-64.77, 0],
    rotation: 0,
    fill: '#ccff00',
    fillOpacity: 0.565,
    filter: 'blur',
  },
  {
    id: 'a0blur',
    type: 'v',
    xy: [17.700625, 0],
    rotation: 0,
    fill: '#7a970a',
    fillOpacity: 1,
    filter: 'blur',
  },
  {
    id: 'a1blur',
    type: 'v',
    xy: [14.0494, 27.0056],
    rotation: 60,
    fill: '#ccff00',
    fillOpacity: 0.565,
    filter: 'blur',
  },
  {
    id: 'x0blur',
    type: 'v',
    xy: [100.0125, 0],
    rotation: 0,
    fill: '#7a970a',
    fillOpacity: 1,
    filter: 'blur',
  },
  {
    id: 'x1blur',
    type: 'v',
    xy: [100.0125, 0],
    rotation: 180,
    fill: '#ccff00',
    fillOpacity: 0.565,
    filter: 'blur',
  },
  {
    id: 'm0',
    type: 'v',
    xy: [-100.0125, 0],
    rotation: 0,
    fill: '#b2de15',
    fillOpacity: 1,
  },
  {
    id: 'm1',
    type: 'v',
    xy: [-64.77, 0],
    rotation: 0,
    fill: '#ccff00',
    fillOpacity: 0.565,
  },
  {
    id: 'a0',
    type: 'v',
    xy: [17.700625, 0],
    rotation: 0,
    fill: '#b2de15',
    fillOpacity: 1,
  },
  {
    id: 'a1',
    type: 'v',
    xy: [14.0494, 27.0056],
    rotation: 60,
    fill: '#ccff00',
    fillOpacity: 0.565,
  },
  {
    id: 'x0',
    type: 'v',
    xy: [100.0125, 0],
    rotation: 0,
    fill: '#b2de15',
    fillOpacity: 1,
  },
  {
    id: 'x1',
    type: 'v',
    xy: [100.0125, 0],
    rotation: 180,
    fill: '#ccff00',
    fillOpacity: 0.565,
  },
];

const formation2 = [
  {
    id: 'x1blur',
    type: 'v',
    xy: [-100.0125, 0],
    rotation: 0,
    fill: '#7a970a',
    fillOpacity: 0,
    filter: 'blur',
  },
  {
    id: 'a0blur',
    type: 'v',
    xy: [-64.77, 0],
    rotation: 0,
    fill: '#ccff00',
    fillOpacity: 0,
    filter: 'blur',
  },
  {
    id: 'm1blur',
    type: 'v',
    xy: [17.700625, 0],
    rotation: 0,
    fill: '#7a970a',
    fillOpacity: 0,
    filter: 'blur',
  },
  {
    id: 'x0blur',
    type: 'v',
    xy: [14.0494, 27.0056],
    rotation: 60,
    fill: '#ccff00',
    fillOpacity: 0,
    filter: 'blur',
  },
  {
    id: 'a1blur',
    type: 'v',
    xy: [100.0125, 0],
    rotation: 0,
    fill: '#7a970a',
    fillOpacity: 0,
    filter: 'blur',
  },
  {
    id: 'm0blur',
    type: 'v',
    xy: [100.0125, 0],
    rotation: 180,
    fill: '#ccff00',
    fillOpacity: 0,
    filter: 'blur',
  },
  {
    id: 'x1',
    type: 'v',
    xy: [-100.0125, 0],
    rotation: 0,
    fill: '#b2de15',
    fillOpacity: 1,
  },
  {
    id: 'a0',
    type: 'v',
    xy: [-64.77, 0],
    rotation: 0,
    fill: '#ccff00',
    fillOpacity: 0.565,
  },
  {
    id: 'm1',
    type: 'v',
    xy: [17.700625, 0],
    rotation: 360,
    fill: '#b2de15',
    fillOpacity: 1,
  },
  {
    id: 'x0',
    type: 'v',
    xy: [14.0494, 27.0056],
    rotation: 60,
    fill: '#ccff00',
    fillOpacity: 0.565,
  },
  {
    id: 'a1',
    type: 'v',
    xy: [100.0125, 0],
    rotation: 360,
    fill: '#b2de15',
    fillOpacity: 1,
  },
  {
    id: 'm0',
    type: 'v',
    xy: [100.0125, 0],
    rotation: 180,
    fill: '#ccff00',
    fillOpacity: 0.565,
  },
];

export const formations = [
  formation0,
  formation1,
  formation2,
];
