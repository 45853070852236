import * as React from 'react';
import { Global, css } from '@emotion/core';
import styled from '@emotion/styled';
import normalize from 'styles/normalize';
import AnimatedLogo from 'components/AnimatedLogo';
import useInterval from 'hooks/useInterval';

const StyledLayoutRoot = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const AnimatedLogoContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Animator = () => {
  const [index, setIndex] = React.useState<number>(0);
  useInterval(() => {
    setIndex(index + 1);
  }, 2500);

  return (
    <AnimatedLogo formationIndex={index} />
  );
};

interface Props {
  className?: string;
  children: React.ReactNode;
}

const LayoutRoot: React.FC<Props> = ({
  children,
  className,
}) => (
  <>
    <Global styles={() => css(normalize)} />
    <AnimatedLogoContainer>
      <Animator />
    </AnimatedLogoContainer>
    <StyledLayoutRoot className={className}>{children}</StyledLayoutRoot>
  </>
);

export default LayoutRoot;
