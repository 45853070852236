import * as React from 'react';
import Helmet from 'react-helmet';
import {
  useStaticQuery,
  graphql,
} from 'gatsby';

import 'modern-normalize';
import 'styles/normalize';

import Header from 'components/Header';
import LayoutRoot from 'components/LayoutRoot';
import LayoutMain from 'components/LayoutMain';

interface SiteData {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      keywords: string;
    };
  };
}

interface Props {
  children: React.ReactNode;
}

const IndexLayout: React.FC<Props> = ({
  children,
}) => {
  const data: SiteData = useStaticQuery(
    graphql`
      query IndexLayoutQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `,
  );
  console.log(data);
  return (
    <LayoutRoot>
      <Helmet
        title={data.site.siteMetadata.title}
        meta={[
          { name: 'description', content: data.site.siteMetadata.description },
          { name: 'keywords', content: data.site.siteMetadata.keywords },
        ]}
      />
      <LayoutMain>{children}</LayoutMain>
    </LayoutRoot>
  );
};

export default IndexLayout;
